import { ClienteTransporte } from './clienteTransporte';
import { PuntoVisita } from './puntoVisita';
import { EstadoTarea } from './estadoTarea';
import { Observacion } from './observacion';
import { Guia } from './guia';
import { C } from '../commons/constantes';
import { dateToString, dateAndHourToString, obtenerRangoFechaEstimada } from '../commons';
import { Paquete } from './paquete';
import { Autorizacion } from './autorizacion';
import { SolicitudPedido } from './solicitudPedido';
import { Marca } from './marca';

export class Tarea {
    //PK
    tareaId : number;
    puntoVisitaId : number;
    viajeId : number;

    //Atributos
    fechaEstimadaInicio : Date;  //deprecate
    fechaEstimadaFin : Date;     //deprecate

    fechaEstimadaLlegadaInicio: Date;
    fechaEstimadaLlegadaFin: Date;
    fechaEstimadaLlegadaInicioActualizada: Date;
    fechaEstimadaLlegadaFinActualizada: Date;
    fechaEstimadaLlegadaInicioToFiltro: string;
    fechaEstimadaLlegadaInicioActualizadaToFiltro: string;
    fechaEstimadaLlegadaSalidaActualizadaToFiltro: string;

    fechaRealInicio : Date;
    fechaRealFin : Date;
    fechaInicioDescarga: Date;

    tipoTarea : number;
    cantidadBultos : number;
    peso : number;
    volumen :number;
    valor : number;
    nroTarea : string;
    nroTareaAlternativo : string;
    adicional1 : string;
    adicional2 : string;
    guiaDetalle : string;
    dentroRadioControl : number;
    uuid : string;
    tipoUuid : number;
    placaExterna : string;
    cantidadVisitas: string;
    conductorExterno : string;
    ventanaHorariaInicio : string;
    ventanaHorariaFin : string;
    ventanaHorariaInicio2 : string;
    ventanaHorariaFin2 : string;
    sensorMaximo : string;
    sensorMinimo : string;
    estadoLiquidacionPaquete : number;
    estadoLiquidacionRecojo : number;

    //Relaciones
    clienteTransporte : ClienteTransporte;
    puntoVisita : PuntoVisita;
    autorizacion : Autorizacion;
    estadoTarea : EstadoTarea;
    marcaObj: Marca;
    solicitudPedido : SolicitudPedido;
    listObservacion : Observacion [];
    listGuia : Guia [];
    listPaquete : Paquete[];

    //Otras Variables
    estadoRegistro : string;
    resultadoRegistro : string;
    estadoTareaCliente : EstadoTarea;
    rangoFechaEstimada: string;
    rangoFechaEstimadaActualizado: string;
    
    //listas de sensores
    listSensoresMaximo : Array<any>;
    listSensoresMinimo : Array<any>;

    //Metodos
    copiar(objeto : any){
        //Propios
        this.tareaId = objeto.tareaId;
        this.puntoVisitaId = objeto.puntoVisitaId;
        this.viajeId = objeto.viajeId;
        this.fechaEstimadaInicio = objeto.fechaEstimadaInicio;
        this.fechaEstimadaFin = objeto.fechaEstimadaFin;
        this.fechaEstimadaLlegadaInicio = objeto.fechaEstimadaLlegadaInicio;
        this.fechaEstimadaLlegadaFin = objeto.fechaEstimadaLlegadaFin;
        this.fechaEstimadaLlegadaInicioActualizada = objeto.fechaEstimadaLlegadaInicioActualizada;
        this.fechaEstimadaLlegadaFinActualizada = objeto.fechaEstimadaLlegadaFinActualizada;
        this.fechaRealInicio = objeto.fechaRealInicio;
        this.fechaRealFin = objeto.fechaRealFin;
        this.fechaInicioDescarga = objeto.fechaInicioDescarga;
        this.tipoTarea = objeto.tipoTarea;
        this.cantidadBultos = objeto.cantidadBultos;
        this.peso = objeto.peso;
        this.volumen = objeto.volumen;
        this.valor = objeto.valor;
        this.nroTarea = objeto.nroTarea;
        this.nroTareaAlternativo = objeto.nroTareaAlternativo;
        this.adicional1 = objeto.adicional1;
        this.adicional2 = objeto.adicional2;
        this.guiaDetalle = objeto.guiaDetalle;
        this.dentroRadioControl = objeto.dentroRadioControl;
        this.uuid = objeto.uuid;
        this.tipoUuid = objeto.tipoUuid;
        this.ventanaHorariaInicio = objeto.ventanaHorariaInicio;
        this.ventanaHorariaFin = objeto.ventanaHorariaFin;
        this.ventanaHorariaInicio2 = objeto.ventanaHorariaInicio2;
        this.ventanaHorariaFin2 = objeto.ventanaHorariaFin2;
        this.sensorMaximo = objeto.sensorMaximo;
        this.sensorMinimo = objeto.sensorMinimo;
        this.conductorExterno = objeto.conductorExterno;
        this.placaExterna = objeto.placaExterna;
        this.cantidadVisitas = objeto.cantidadVisitas;
        this.estadoRegistro = objeto.estadoRegistro;
        this.resultadoRegistro = objeto.resultadoRegistro;
        this.estadoLiquidacionPaquete = objeto.estadoLiquidacionPaquete; 
        this.estadoLiquidacionRecojo = objeto.estadoLiquidacionRecojo;
        
        //Relaciones
        if(objeto.clienteTransporte != null){
            this.clienteTransporte = new ClienteTransporte();
            this.clienteTransporte.copiar(objeto.clienteTransporte);
        }
        if(objeto.puntoVisita != null){
            this.puntoVisita = new PuntoVisita();
            this.puntoVisita.copiar(objeto.puntoVisita);
        }
        if(objeto.autorizacion != null){
            this.autorizacion = new Autorizacion();
            this.autorizacion.copiar(objeto.autorizacion);
        }
        if(objeto.estadoTarea != null){
            this.estadoTarea = new EstadoTarea();
            this.estadoTarea.copiar(objeto.estadoTarea);
        }
        if(objeto.estadoTareaCliente != null){
            this.estadoTareaCliente = new EstadoTarea();
            this.estadoTareaCliente.copiar(objeto.estadoTareaCliente);
        }
        if(objeto.solicitudPedido != null) {
            this.solicitudPedido = new SolicitudPedido();
            this.solicitudPedido.copiar(objeto.solicitudPedido);
        }
        if(objeto.marcaObj != null){
            this.marcaObj = new Marca();
            this.marcaObj.copiar(objeto.marcaObj);
        }

        if(objeto.listObservacion != null){
            let observacion : Observacion;
            this.listObservacion = new Array();
            for (let r of objeto.listObservacion) {
                if(r != null){
                    observacion = new Observacion();
                    observacion.copiar(r);
                    this.listObservacion.push(observacion);
                }
            }
        }
        if(objeto.listGuia != null){
            let guia : Guia;
            this.listGuia = new Array();
            for (let r of objeto.listGuia) {
                if(r != null){
                    guia = new Guia();
                    guia.copiar(r);
                    this.listGuia.push(guia);
                }
            }
        }
        if(objeto.listPaquetes != null || objeto.listPaquete != null){
            let paquete : Paquete;
            this.listPaquete = new Array();
            for (let r of (objeto.listPaquetes || objeto.listPaquete)) {
                if(r != null){
                    paquete = new Paquete();
                    paquete.copiar(r);
                    this.listPaquete.push(paquete);
                }
            }
        }

        if(this.sensorMinimo){
            //convertimos los sensores minimos a un objeto
            let sensorMinimoArray = this.sensorMinimo.split(",");
            let sensorMinimoString;

            this.listSensoresMinimo = sensorMinimoArray.map(function(obj){
            var rObj = {};
            sensorMinimoString = obj.split("=");
            rObj["sensorId"] = sensorMinimoString[0];
            rObj["minimo"] = parseFloat(sensorMinimoString[1]).toFixed(1);
            return rObj;
            });
        }

        if(this.sensorMaximo){
            //convertimos los sensores maximos a un objeto
            let sensorMaximoArray = this.sensorMaximo.split(",");
            let sensorMaximoString;

            this.listSensoresMaximo = sensorMaximoArray.map(function(obj){
            var rObj = {};
            sensorMaximoString = obj.split("=");
            rObj["sensorId"] = sensorMaximoString[0];
            rObj["maximo"] = parseFloat(sensorMaximoString[1]).toFixed(1)
            return rObj;
            });
        }
       
        this.rangoFechaEstimada = obtenerRangoFechaEstimada(this.fechaEstimadaLlegadaInicio, this.fechaEstimadaLlegadaFin);
        this.rangoFechaEstimadaActualizado = obtenerRangoFechaEstimada(this.fechaEstimadaLlegadaInicioActualizada, this.fechaEstimadaLlegadaFinActualizada);

        this.fechaEstimadaLlegadaInicioToFiltro = this.fechaEstimadaLlegadaIniToFiltro();
        this.fechaEstimadaLlegadaInicioActualizadaToFiltro = this.fechaEstimadaLlegadaIniActualizadaToFiltro();
        this.fechaEstimadaLlegadaSalidaActualizadaToFiltro = this.fechaEstimadaLlegadaSaliActualizadaToFiltro();
    }

    get fechaEstimadaLlegadaToFiltro() : string{
        let label = '';
        if(typeof(this.fechaEstimadaInicio) != "undefined" && this.fechaEstimadaInicio != null){
            label = dateToString(this.fechaEstimadaInicio);
        }
        return label;
    }

    fechaEstimadaLlegadaIniToFiltro() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaInicio) != "undefined" && this.fechaEstimadaLlegadaInicio != null){
            label = dateToString(this.fechaEstimadaLlegadaInicio);
        }
        return label;
    }

    fechaEstimadaLlegadaIniActualizadaToFiltro() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaInicioActualizada) != "undefined" && this.fechaEstimadaLlegadaInicioActualizada != null){
            label = dateToString(this.fechaEstimadaLlegadaInicioActualizada);
        }
        return label;
    }

    fechaEstimadaLlegadaSaliActualizadaToFiltro() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaFinActualizada) != "undefined" && this.fechaEstimadaLlegadaFinActualizada != null){
            label = dateToString(this.fechaEstimadaLlegadaFinActualizada);
        }
        return label;
    }

    get fechaRealInicioToFiltro() : string{
        let label = '';
        if(typeof(this.fechaRealInicio) != "undefined" && this.fechaRealInicio != null){
            label = dateToString(this.fechaRealInicio);
        }
        return label;
    }

    get fechaRealFinToFiltro() : string{
        let label = '';
        if(typeof(this.fechaRealFin) != "undefined" && this.fechaRealFin != null){
            label = dateToString(this.fechaRealFin);
        }
        return label;
    }

    get fechaInicioDescargaToFiltro(): string {
        let label = '';
        if(typeof(this.fechaInicioDescarga) != "undefined" && this.fechaInicioDescarga != null){
            label = dateToString(this.fechaInicioDescarga);
        }
        return label;
    }

    get fechaRealInicioToString() : string{
        let label = '';
        if(typeof(this.fechaRealInicio) != "undefined" && this.fechaRealInicio != null){
            label = dateAndHourToString(this.fechaRealInicio);
        }
        return label;
    }

    get fechaRealFinToString() : string{
        let label = '';
        if(typeof(this.fechaRealFin) != "undefined" && this.fechaRealFin != null){
            label = dateAndHourToString(this.fechaRealFin);
        }
        return label;
    }

    get fechaInicioDescargaToString() : string {
        let label = '';
        if(typeof(this.fechaInicioDescarga) != "undefined" && this.fechaInicioDescarga != null){
            label = dateAndHourToString(this.fechaInicioDescarga);
        }
        return label;
    }

    get tipoTareaToString() : string{
        switch(Number(this.tipoTarea)){
            case C.TAREA.TIPO.ENTREGA: return "MODEL.entrega.titulo";
            case C.TAREA.TIPO.RECOJO: return "MODEL.recojo.titulo";
            case C.TAREA.TIPO.RETORNO: return "MODEL.retorno.titulo";
        }
        return "COMMON.general.vacio";
    }

    get noEsEditable() : boolean{
        let result : boolean = false;
        if(this.estadoTarea != null && this.estadoTarea.estado != null){
            return this.estadoTarea.estado > C.TAREA.ESTADO.EN_RUTA;
        }
        return result;
    }

    get dentroRadioControlToString() : string{
        switch(+this.dentroRadioControl){
            case C.TAREA.RADIO_CONTROL.DENTRO: return "COMMON.constantes.estados-radio-control.dentro";
            case C.TAREA.RADIO_CONTROL.FUERA: return "COMMON.constantes.estados-radio-control.fuera";
        }
        return "";
    }

    get fechaEstimadaInicioActualizadaToString() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaInicioActualizada) != "undefined" && this.fechaEstimadaLlegadaInicioActualizada != null){
            label = dateAndHourToString(this.fechaEstimadaLlegadaInicioActualizada);
        }
        return label;
    }

    get fechaEstimadaSalidaActualizadaToString() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaFinActualizada) != "undefined" && this.fechaEstimadaLlegadaFinActualizada != null){
            label = dateAndHourToString(this.fechaEstimadaLlegadaFinActualizada);
        }
        return label;
    }

    get fechaEstimadaLlegadaToString() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaInicio) != "undefined" && this.fechaEstimadaLlegadaInicio != null){
            label = dateAndHourToString(this.fechaEstimadaLlegadaInicio);
        }
        return label;
    }

    get fechaEstimadaSalidaToString() : string{
        let label = '';
        if(typeof(this.fechaEstimadaLlegadaFin) != "undefined" && this.fechaEstimadaLlegadaFin != null){
            label = dateAndHourToString(this.fechaEstimadaLlegadaFin);
        }
        return label;
    }

}
